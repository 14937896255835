import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './Top.module.scss';

const Top: FC<IProps> = (props) => {
    return (
        <div
            className={classNames(styles.top, props.className)}
            style={props.style}
        >
            {
                props.titles.map(
                    (title) =>
                        <div key={title}>
                            {title}
                        </div>
                )
            }
        </div>
    );
};

export default Top;
