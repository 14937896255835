import config from 'config';
import {
    useEffect,
} from 'react';

export default function useRedirectProtocol() {
    useEffect(() => {
        if (config.MODE === 'DEV') {
            return;
        }
        if (window.location.protocol !== 'https:') {
            window.location.protocol = 'https:';
        }
    }, []);
}
