import {
    IDateDescription,
    IDateResult,
    IDescriptionNotRequiredNotNullable,
    IDescriptionNotRequiredNullable,
    IDescriptionRequiredNotNullable,
    IDescriptionRequiredNullable,
    IResultNotRequiredNotNullable,
    IResultNotRequiredNullable,
    IResultRequiredNotNullable,
    IResultRequiredNullable,
} from './types';

import {
    NULLABLE_VALUE,
    UNDEFINED_VALUE,
} from '../../constants';

function parseDate(value: string, description: IDescriptionRequiredNullable): IResultRequiredNullable;
function parseDate(value: string, description: IDescriptionRequiredNotNullable): IResultRequiredNotNullable;
function parseDate(value: string, description: IDescriptionNotRequiredNullable): IResultNotRequiredNullable;
function parseDate(value: string, description: IDescriptionNotRequiredNotNullable): IResultNotRequiredNotNullable;
function parseDate(value: string, description: IDescriptionNotRequiredNotNullable): IResultNotRequiredNotNullable;
function parseDate(value: string, description: IDateDescription): IDateResult {
    if (value === UNDEFINED_VALUE) {
        if (description.isRequired) {
            return {
                value: description.default(),
            };
        } else {
            return {
                value: undefined,
            };
        }
    }
    if (value === NULLABLE_VALUE) {
        if (description.isNullable) {
            return {
                value: null,
            };
        } else {
            return {
                value: description.default(),
            };
        }
    }

    const firstDate = new Date(value);

    if (!isNaN(firstDate.getDate())) {
        return {
            value: firstDate,
        };
    }

    const secondDate = new Date(parseInt(value));

    if (!isNaN(secondDate.getDate())) {
        return {
            value: secondDate,
        };
    }

    return {
        value: description.default(),
    };
}

export default parseDate;
