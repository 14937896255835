import {
    IFieldType,
} from '../parseField/types';
import {
    IArrayDescription,
    IArrayResult,
    IDescriptionNotRequiredNotNullable,
    IDescriptionNotRequiredNullable,
    IDescriptionRequiredNotNullable,
    IDescriptionRequiredNullable,
    IResultNotRequiredNotNullable,
    IResultNotRequiredNullable,
    IResultRequiredNotNullable,
    IResultRequiredNullable,
} from './types';

import {
    NULLABLE_VALUE,
    UNDEFINED_VALUE,
} from '../constants';
import {
    parseField,
} from '../parseField';

function parseArray<IType extends IFieldType>(value: string[] | string, description: IDescriptionRequiredNullable<IType>): IResultRequiredNullable<IType>;
function parseArray<IType extends IFieldType>(value: string[] | string, description: IDescriptionRequiredNotNullable<IType>): IResultRequiredNotNullable<IType>;
function parseArray<IType extends IFieldType>(value: string[] | string, description: IDescriptionNotRequiredNullable<IType>): IResultNotRequiredNullable<IType>;
function parseArray<IType extends IFieldType>(value: string[] | string, description: IDescriptionNotRequiredNotNullable<IType>): IResultNotRequiredNotNullable<IType>;
function parseArray<IType extends IFieldType>(value: string[] | string, description: IDescriptionNotRequiredNotNullable<IType>): IResultNotRequiredNotNullable<IType>;
function parseArray<IType extends IFieldType>(value: string[] | string, description: IArrayDescription<IType>): IArrayResult<IFieldType> {
    if (value === UNDEFINED_VALUE) {
        if (description.isRequired) {
            return {
                // @ts-ignore
                value: description.default,
            };
        } else {
            return {
                value: undefined,
            };
        }
    }
    if (value === NULLABLE_VALUE) {
        if (description.isNullable) {
            return {
                value: null,
            };
        } else {
            return {
                // @ts-ignore
                value: description.default,
            };
        }
    }

    return {
        value: (Array.isArray(value) ? value : [value])
            .map((item) => parseField(item, description.items))
            .map((item) => item.value),
    };
}

export default parseArray;
