import {
    IMerchantUser,
} from './types';
import {
    IPatchBySuperAdminBody,
    IPatchByUserBody,
    IPostBySuperAdminBody,
} from './types/requests';
import {
    IDeleteBySuperAdmin,
    IGetByUser,
    IGetOneByUser,
    IPatchBySuperAdmin,
    IPatchByUser,
    IPostBySuperAdmin,
    IPostRecoverBySuperAdmin,
} from './types/responses';

import {
    useState,
} from 'react';
import {
    atom,
    useRecoilState,
} from 'recoil';

import Entity from 'helpers/Entity';

import * as fetches from './fetches';

const merchantsAtom = atom<IMerchantUser[] | null>({
    key: 'merchantsAtom',
    default: null,
});

export function useMerchantsByUser() {
    const [merchants, setMerchants] = useRecoilState(merchantsAtom);

    const getMerchants = async (): Promise<IGetByUser> => {
        const res = await fetches.getByUser();

        if (!res.merchants || res.error) {
            console.log(res.error);

            return res;
        }

        setMerchants(res.merchants);

        return res;
    };
    const postRecoverMerchantBySuperAdmin = async (id: string): Promise<IPostRecoverBySuperAdmin> => {
        const res = await fetches.postRecoverBySuperAdmin(id);

        if (!res.merchant || res.error) {
            console.log(res.error);

            return res;
        }

        setMerchants((merchants) => {
            return Entity.replace(res.merchant, merchants);
        });

        return res;
    };
    const deleteMerchantBySuperAdmin = async (id: string): Promise<IDeleteBySuperAdmin> => {
        const res = await fetches.deleteBySuperAdmin(id);

        if (!res.merchant || res.error) {
            console.log(res.error);

            return res;
        }

        setMerchants((merchants) => {
            return Entity.replace(res.merchant, merchants);
        });

        return res;
    };
    const addToPaymentMethodInfoTotalBalance = (id: string, paymentMethodInfoId: number, amount: number): void => {
        const merchant = Entity.findOneById(id, merchants);

        if (!merchant) {
            return;
        }

        const paymentMethodInfo = Entity.findOneById(paymentMethodInfoId, merchant.paymentMethodInfos);

        if (!paymentMethodInfo) {
            return;
        }

        const newPaymentMethodInfos = Entity.replace({
            ...paymentMethodInfo,
            totalBalance: String(BigInt(paymentMethodInfo.totalBalance) + BigInt(amount)),
        }, merchant.paymentMethodInfos);

        if (!newPaymentMethodInfos) {
            return;
        }

        const newMerchants = Entity.replace({
            ...merchant,
            paymentMethodInfos: newPaymentMethodInfos,
        }, merchants);

        setMerchants(newMerchants);
    };

    return {
        merchants,
        getMerchants,
        postRecoverMerchantBySuperAdmin,
        deleteMerchantBySuperAdmin,
        addToPaymentMethodInfoTotalBalance,
    };
}

export function useMerchantByUser() {
    const [merchant, setMerchant] = useState<IMerchantUser | null>(null);

    const getMerchant = async (id: string): Promise<IGetOneByUser> => {
        const res = await fetches.getOneByUser(id);

        if (!res.merchant || res.error) {
            console.log(res.error);

            return res;
        }

        setMerchant(res.merchant);

        return res;
    };
    const postMerchantBySuperAdmin = async (body: IPostBySuperAdminBody): Promise<IPostBySuperAdmin> => {
        const res = await fetches.postBySuperAdmin(body);

        if (!res.merchant || res.error) {
            console.log(res.error);

            return res;
        }

        setMerchant(res.merchant);

        return res;
    };
    const patchMerchantBySuperAdmin = async (body: IPatchBySuperAdminBody): Promise<IPatchBySuperAdmin> => {
        const res = await fetches.patchBySuperAdmin(body);

        if (!res.merchant || res.error) {
            console.log(res.error);

            return res;
        }

        setMerchant(res.merchant);

        return res;
    };
    const patchMerchantByUser = async (body: IPatchByUserBody): Promise<IPatchByUser> => {
        const res = await fetches.patchByUser(body);

        if (!res.merchant || res.error) {
            console.log(res.error);

            return res;
        }

        setMerchant(res.merchant);

        return res;
    };
    const addToPaymentMethodInfoTotalBalance = (paymentMethodInfoId: number, amount: number): void => {
        if (!merchant) {
            return;
        }

        const paymentMethodInfo = Entity.findOneById(paymentMethodInfoId, merchant.paymentMethodInfos);

        if (!paymentMethodInfo) {
            return;
        }

        paymentMethodInfo.totalBalance = String(BigInt(paymentMethodInfo.totalBalance) + BigInt(amount));

        setMerchant({
            ...merchant,
            paymentMethodInfos: [...merchant.paymentMethodInfos],
        });
    };

    return {
        merchant,
        getMerchant,
        postMerchantBySuperAdmin,
        patchMerchantBySuperAdmin,
        patchMerchantByUser,
        addToPaymentMethodInfoTotalBalance,
    };
}
