export default function(fileName: string, svg: SVGSVGElement, width: number, height: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        // @ts-ignore
        const html = svg.parentNode.innerHTML;
        const imageSrc = 'data:image/svg+xml;base64,' + btoa(html);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        if (!context) {
            canvas.remove();

            reject();

            return;
        }

        const image = new Image();

        image.src = imageSrc;
        image.width = width;
        image.height = height;
        image.onload = () => {
            context.drawImage(image, 0, 0, width, height);
            canvas.toBlob((file) => {
                canvas.remove();
                image.remove();

                if (!file) {
                    reject();

                    return;
                }

                const a = document.createElement('a');

                a.href = URL.createObjectURL(file);
                a.download = fileName;

                a.click();
                URL.revokeObjectURL(a.href);
                a.remove();
                resolve();
            }, 'image/png');
        };
    });
}
