import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useState,
} from 'react';
import ReactQRCode from 'react-qr-code';

import {
    downloadSVG,
} from 'helpers/functions';

import styles from './QR.module.scss';

const QR: FC<IProps> = (props) => {
    const [imageRef, setImageRef] = useState<SVGSVGElement | null>(null);

    const onClick = async () => {
        if (!imageRef) {
            return;
        }

        await downloadSVG(props.filename, imageRef, 600, 600);
    };

    return (
        <div className={classNames(props.className, styles.qr)}>
            <ReactQRCode
                // @ts-ignore
                ref={setImageRef}
                className={styles.image}
                value={props.value}
                onClick={onClick}
            />
        </div>
    );
};

export default QR;
