import {
    IUserPositionUser,
} from './types';
import {
    IGetByUser,
} from './types/responses';

import {
    atom,
    useRecoilState,
} from 'recoil';

import * as fetches from './fetches';

const userPositionsAtom = atom<IUserPositionUser[] | null>({
    key: 'userPositionsAtom',
    default: null,
});

export function useUserPositionsByUser() {
    const [userPositions, setUserPositions] = useRecoilState(userPositionsAtom);

    const getUserPositions = async (): Promise<IGetByUser> => {
        const res = await fetches.getByUser();

        if (!res.userPositions || res.error) {
            console.log(res.error);

            return res;
        }

        setUserPositions(res.userPositions);

        return res;
    };

    return {
        userPositions,
        getUserPositions,
    };
}
