import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './EmptyResult.module.scss';

const EmptyResult: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.emptyResult, props.className)}>
            Nothing to show :C
        </div>
    );
};

export default EmptyResult;
