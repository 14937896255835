import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    FormEvent,
    useRef,
} from 'react';

import {
    useInputMask,
} from 'tools/hooks';

import ArrowButton from '../ArrowButton';

import {
    MASK,
} from './constants';
import {
    formatValue,
} from './functions';

import styles from './PageInput.module.scss';

const PageInput: FC<IProps> = (props) => {
    const {value, setValue, inputProps} = useInputMask({
        mask: MASK,
        value: '',
    });

    const inputRef = useRef<HTMLInputElement>(null);

    const onConfirm = () => {
        if (!value) {
            return;
        }
        if (!props.total) {
            setValue('');

            return;
        }

        let formattedValue = formatValue(value);

        if (!formattedValue) {
            formattedValue = 1;
        }
        if (formattedValue > props.pagesCount) {
            formattedValue = props.pagesCount;
        }

        props.onConfirm((formattedValue - 1) * props.take);
        setValue('');
    };
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onConfirm();
    };

    return (
        <form
            className={classNames(styles.pageInput, props.className)}
            autoComplete={'off'}
            onClick={() => inputRef.current?.focus()}
            onSubmit={onSubmit}
        >
            <div className={styles.label}>
                Go to page
            </div>
            <input
                ref={inputRef}
                className={styles.input}
                value={value}
                autoComplete={'off'}
                {...inputProps}
            />
            <ArrowButton
                direction={'right'}
                isDisabled={props.isDisabled || !value}
                onClick={onConfirm}
            />
        </form>
    );
};

export default PageInput;
