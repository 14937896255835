import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';
import {
    Link,
} from 'react-router-dom';

import styles from './NavItem.module.scss';

const NavItem: FC<IProps> = (props) => {
    if (!props.isShown) {
        return null;
    }

    return (
        <Link
            className={
                classNames(styles.navItem, props.className, {
                    [styles.isActive]: props.isActive,
                })
            }
            to={props.to}
        >
            {props.text}
        </Link>
    );
};

export default NavItem;
