import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import ArrowButton from './ArrowButton';
import PageInput from './PageInput';

import styles from './Pagination.module.scss';

const Pagination: FC<IProps> = (props) => {
    const onChange = async (skip: number) => {
        if (props.isDisabled) {
            return;
        }

        props.onChange(skip);
    };

    if (props.total === null) {
        return null;
    }

    const currentPage = Math.floor(props.skip / props.take) + 1;
    const pagesCount = Math.ceil(props.total / props.take);

    return (
        <div className={classNames(styles.pagination, props.className)}>
            <div className={styles.pages}>
                <ArrowButton
                    direction={'left'}
                    isDisabled={!props.skip || props.isDisabled}
                    onClick={() => onChange(props.skip - props.take)}
                />
                {
                    props.skip !== 0 &&
                    <div
                        className={styles.page}
                        onClick={() => onChange(0)}
                    >
                        1
                    </div>
                }
                {
                    currentPage > 2 &&
                    <div
                        className={styles.page}
                        onClick={() => onChange((currentPage - 2) * props.take)}
                    >
                        {currentPage - 1}
                    </div>
                }
                <div className={styles.currentPage}>
                    {currentPage}
                </div>
                {
                    currentPage < pagesCount - 1 &&
                    <div
                        className={styles.page}
                        onClick={() => onChange(currentPage * props.take)}
                    >
                        {currentPage + 1}
                    </div>
                }
                {
                    !!pagesCount && currentPage !== pagesCount &&
                    <div
                        className={styles.page}
                        onClick={() => onChange((pagesCount - 1) * props.take)}
                    >
                        {pagesCount}
                    </div>
                }
                <ArrowButton
                    direction={'right'}
                    isDisabled={props.skip >= (pagesCount - 1) * props.take || props.isDisabled}
                    onClick={() => onChange(props.skip + props.take)}
                />
            </div>
            <PageInput
                pagesCount={pagesCount}
                take={props.take}
                total={props.total}
                isDisabled={props.isDisabled}
                onConfirm={onChange}
            />
        </div>
    );
};

export default Pagination;
