import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './Badge.module.scss';

const Badge: FC<IProps> = (props) => {
    return (
        <div
            className={
                classNames(styles.badge, props.className, {
                    [styles.isGreen]: props.type === 'green',
                    [styles.isRed]: props.type === 'red',
                    [styles.isPurple]: props.type === 'purple',
                    [styles.isGray]: props.type === 'gray',
                })
            }
        >
            {props.children}
        </div>
    );
};

export default Badge;
