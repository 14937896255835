import {
    IDescriptionNotRequiredNotNullable,
    IDescriptionNotRequiredNullable,
    IDescriptionRequiredNotNullable,
    IDescriptionRequiredNullable,
    IResultNotRequiredNotNullable,
    IResultNotRequiredNullable,
    IResultRequiredNotNullable,
    IResultRequiredNullable,
    IStringDescription,
    IStringResult,
} from './types';

import {
    NULLABLE_VALUE,
    UNDEFINED_VALUE,
} from '../../constants';

function parseString(value: string, description: IDescriptionRequiredNullable): IResultRequiredNullable;
function parseString(value: string, description: IDescriptionRequiredNotNullable): IResultRequiredNotNullable;
function parseString(value: string, description: IDescriptionNotRequiredNullable): IResultNotRequiredNullable;
function parseString(value: string, description: IDescriptionNotRequiredNotNullable): IResultNotRequiredNotNullable;
function parseString(value: string, description: IStringDescription): IStringResult {
    if (value === UNDEFINED_VALUE) {
        if (description.isRequired) {
            return {
                value: description.default,
            };
        } else {
            return {
                value: undefined,
            };
        }
    }
    if (value === NULLABLE_VALUE) {
        if (description.isNullable) {
            return {
                value: null,
            };
        } else {
            return {
                value: description.default,
            };
        }
    }
    if (description.enum) {
        if (!description.enum.includes(value)) {
            return {
                value: description.default,
            };
        }

        return {
            value,
        };
    }

    return {
        value,
    };
}

export default parseString;
