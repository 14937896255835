import {
    IRef,
} from './types';

import {
    useDefaultedRef,
} from 'tools/hooks';

export function useHeaderRef() {
    return useDefaultedRef<IRef>({
        getHeight(): number {
            return 0;
        },
    });
}
