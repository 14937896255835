import {
    IProps,
    IRef,
} from './types';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from 'react';

import AuthInfo from './AuthInfo';
import Navigation from './Navigation';

import logoImage from './media/logo.svg';

import styles from './Header.module.scss';

const Header = forwardRef<IRef, IProps>((props, ref) => {
    const headerRef = useRef<HTMLElement>(null);

    useImperativeHandle(ref, () => {
        return {
            getHeight(): number {
                return headerRef.current?.clientHeight ?? 0;
            },
        };
    });

    return (
        <header
            ref={headerRef}
            className={classNames(styles.header, props.className)}
        >
            <div className={styles.left}>
                <img
                    className={styles.logo}
                    src={logoImage}
                    alt={''}
                />
                <Navigation/>
            </div>
            <AuthInfo/>
        </header>
    );
});

export default Header;
