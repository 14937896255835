import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './BlockTitle.module.scss';

const BlockTitle: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.blockTitle, props.className)}>
            {props.text}
        </div>
    );
};

export default BlockTitle;
