import {
    RefObject,
    useCallback,
    useEffect,
} from 'react';

export default function<IRef extends HTMLElement>(ref: RefObject<IRef>, handler: () => void, dependencies?: any[]) {
    const handleClick = useCallback((e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            handler();
        }
    }, [...(dependencies ?? [])]);

    useEffect(() => {
        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref, handleClick]);
}
