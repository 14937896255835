import {
    IObject,
    IQueryDescription,
} from './types';

import {
    parseStringedQuery,
    stringifyArray,
    stringifyField,
} from 'tools/Query/functions';
import {
    getDefaultArray,
    parseArray,
} from 'tools/Query/parseArray';
import {
    getDefaultValue,
    parseField,
} from 'tools/Query/parseField';

import {
    UNDEFINED_VALUE,
} from './constants';

class Query {
    public static stringify<IQuery extends IObject>(query: IQuery | null | undefined): string {
        if (!query) {
            return '';
        }

        const res: string[] = [];
        const emptyValues = [undefined, null, ''];

        Object
            .entries(query)
            .forEach(([key, value]) => {
                if (emptyValues.includes(value)) {
                    return;
                }
                if (Array.isArray(value)) {
                    const stringValue = stringifyArray(key, value);

                    res.push(...stringValue);
                } else {
                    const stringValue = stringifyField(key, value);

                    res.push(stringValue);
                }
            });

        if (!res.length) {
            return '';
        }

        return `?${res.join('&')}`;
    }

    public static getDefault<IQuery extends IObject>(description: IQueryDescription<IQuery>): IQuery {
        const res = Object
            .entries(description)
            .map(([key, propertyDescription]) => {
                if (propertyDescription.type === 'array') {
                    const value = getDefaultArray(propertyDescription);

                    return [key, value];
                } else {
                    const value = getDefaultValue(propertyDescription);

                    return [key, value];
                }
            });

        return Object.fromEntries(res);
    }

    public static parse<IQuery extends IObject>(queryString: string, description: IQueryDescription<IQuery>): IQuery {
        const stringedQuery = parseStringedQuery<IQuery>(queryString);
        const res = Object
            .entries(description)
            .map(([key, propertyDescription]) => {
                const value = stringedQuery[key] ?? UNDEFINED_VALUE;

                if (propertyDescription.type === 'array') {
                    const field = parseArray(value as string[] | string, propertyDescription);

                    return [key, field.value];
                } else {
                    const field = parseField(value as string, propertyDescription);

                    return [key, field.value];
                }
            });

        return Object.fromEntries(res);
    }

    public static merge<IQuery extends IObject>(queryString: string, query: IQuery): string {
        const stringedQuery = parseStringedQuery<IObject>(queryString);

        return Query.stringify({
            ...stringedQuery,
            ...query,
        });
    }

    public static differ<IQuery extends IObject>(queryString: string, query: IQuery): string {
        const stringedQuery = parseStringedQuery<IObject>(queryString);
        const queryKeys = Object.keys(query);

        Object
            .keys(stringedQuery)
            .forEach((key) => {
                if (!queryKeys.includes(key)) {
                    return;
                }

                stringedQuery[key] = undefined;
            });

        return Query.stringify(stringedQuery);
    }
}

export default Query;
