import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import ArrowImage from './ArrowImage';

import styles from './ArrowButton.module.scss';

const ArrowButton: FC<IProps> = (props) => {
    const onClick = () => {
        if (props.isDisabled) {
            return;
        }

        props.onClick();
    };

    return (
        <ArrowImage
            className={
                classNames(styles.arrowButton, {
                    [styles.isLeft]: props.direction === 'left',
                    [styles.isDisabled]: props.isDisabled,
                })
            }
            onClick={onClick}
        />
    );
};

export default ArrowButton;
