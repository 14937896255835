import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './ErrorMessage.module.scss';

const ErrorMessage: FC<IProps> = (props) => {
    if (!props.text) {
        return null;
    }

    return (
        <div
            className={
                classNames(styles.errorMessage, props.className, {
                    [styles.isSmall]: props.size === 'small',
                })
            }
        >
            {props.text}
        </div>
    );
};

export default ErrorMessage;
