import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    Children,
    FC,
} from 'react';

import Loading from '../Loading';

import EmptyResult from './EmptyResult';
import Top from './Top';

import styles from './ListTable.module.scss';

const ListTable: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.listTable, props.className)}>
            <Top
                className={props.rowClassName}
                titles={props.titles}
                style={props.rowStyle}
            />
            {
                props.isPending ?
                    <Loading/> :
                    Children.count(props.children) ?
                        props.children :
                        <EmptyResult/>
            }
        </div>
    );
};

export default ListTable;
