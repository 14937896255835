import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import {
    formatDefaultValue,
} from './functions';

import styles from './LineClamp.module.scss';

const LineClamp: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.lineClamp, props.className)}>
            {formatDefaultValue(props.text)}
            <div className={styles.content}>
                {
                    props.text
                        .split('\n')
                        .map(
                            (line, i) =>
                                <div key={i}>
                                    {line}
                                </div>
                        )
                }
            </div>
        </div>
    );
};

export default LineClamp;
