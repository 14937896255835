import {
    IUserMe,
    IUserSuperAdmin,
    IUserUser,
} from './types';
import {
    IGetBySuperAdminQuery,
    IGetByUserQuery,
    IPatchBySuperAdminBody,
    IPostBySuperAdminBody,
    IPostResetPasswordByUserBody,
} from './types/requests';
import {
    IDeleteBySuperAdmin,
    IGetBySuperAdmin,
    IGetByUser,
    IGetOneByMe,
    IGetOneBySuperAdmin,
    IPatchBySuperAdmin,
    IPostBySuperAdmin,
    IPostRecoverBySuperAdmin,
    IPostResetPasswordByUser,
} from './types/responses';

import {
    useState,
} from 'react';
import {
    atom,
    useRecoilState,
} from 'recoil';

import Entity from 'helpers/Entity';

import * as fetches from './fetches';

const authUserAtom = atom<IUserMe | null>({
    key: 'authUserAtom',
    default: null,
});

export function useUserByMe() {
    const [authUser, setAuthUser] = useRecoilState(authUserAtom);

    const getAuthUser = async (): Promise<IGetOneByMe> => {
        const res = await fetches.getOneByMe();

        if (!res.user || res.error) {
            console.log(res.error);

            return res;
        }

        setAuthUser(res.user);

        return res;
    };
    const postResetPassword = async (body: IPostResetPasswordByUserBody): Promise<IPostResetPasswordByUser> => {
        const res = await fetches.postResetPasswordByUser(body);

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };

    return {
        authUser,
        getAuthUser,
        postResetPassword,
    };
}

export function useUsersByUser() {
    const [users, setUsers] = useState<IUserUser[] | null>(null);

    const getUsers = async (query: IGetByUserQuery): Promise<IGetByUser> => {
        const res = await fetches.getByUser(query);

        if (!res.users || res.error) {
            console.log(res.error);

            return res;
        }

        setUsers(res.users);

        return res;
    };

    return {
        users,
        getUsers,
    };
}

export function useUsersBySuperAdmin() {
    const [users, setUsers] = useState<IUserSuperAdmin[] | null>(null);
    const [usersTotal, setUsersTotal] = useState<number | null>(null);

    const getUsers = async (query: IGetBySuperAdminQuery): Promise<IGetBySuperAdmin> => {
        setUsers(null);

        const res = await fetches.getBySuperAdmin(query);

        if (!res.users || res.total === undefined || res.error) {
            console.log(res.error);

            return res;
        }

        setUsers(res.users);
        setUsersTotal(res.total);

        return res;
    };
    const postRecoverUser = async (id: number): Promise<IPostRecoverBySuperAdmin> => {
        const res = await fetches.postRecoverBySuperAdmin(id);

        if (!res.user || res.error) {
            console.log(res.error);

            return res;
        }

        setUsers((users) => {
            return Entity.replace(res.user, users);
        });

        return res;
    };
    const deleteUser = async (id: number): Promise<IDeleteBySuperAdmin> => {
        const res = await fetches.deleteSuperAdmin(id);

        if (!res.user || res.error) {
            console.log(res.error);

            return res;
        }

        setUsers((users) => {
            return Entity.replace(res.user, users);
        });

        return res;
    };

    return {
        users,
        usersTotal,
        getUsers,
        postRecoverUser,
        deleteUser,
    };
}

export function useUserBySuperAdmin() {
    const [user, setUser] = useState<IUserSuperAdmin | null>(null);

    const getUser = async (id: number): Promise<IGetOneBySuperAdmin> => {
        const res = await fetches.getOneBySuperAdmin(id);

        if (!res.user || res.error) {
            console.log(res.error);

            return res;
        }

        setUser(res.user);

        return res;
    };
    const postUser = async (body: IPostBySuperAdminBody): Promise<IPostBySuperAdmin> => {
        const res = await fetches.postBySuperAdmin(body);

        if (res.duplicatedUsernameError) {
            return res;
        }
        if (!res.user || res.error) {
            console.log(res.error);

            return res;
        }

        setUser(res.user);

        return res;
    };
    const patchUser = async (body: IPatchBySuperAdminBody): Promise<IPatchBySuperAdmin> => {
        const res = await fetches.patchBySuperAdmin(body);

        if (res.duplicatedUsernameError) {
            return res;
        }
        if (!res.user || res.error) {
            console.log(res.error);

            return res;
        }

        setUser(res.user);

        return res;
    };

    return {
        user,
        getUser,
        postUser,
        patchUser,
    };
}
