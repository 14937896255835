import {
    IAUthSuperAdmin,
} from './types';
import {
    IPostByUserBody,
    IPostCheckByUserBody,
} from './types/requests';
import {
    IGetBySuperAdmin,
    IPostByUser,
    IPostLogoutByUser,
} from './types/responses';

import {
    useState,
} from 'react';

import * as fetches from './fetches';

export function useAuthByUser() {
    const [withTOTP, setWithTOTP] = useState(false);

    const postCheckAuth = async (body: IPostCheckByUserBody) => {
        const res = await fetches.postCheckByUser(body);

        if (res.withTOTP === undefined || res.error) {
            console.log(res.error);

            return res;
        }

        setWithTOTP(res.withTOTP);

        return res;
    };
    const postAuth = async (body: IPostByUserBody): Promise<IPostByUser> => {
        const res = await fetches.postByUser(body);

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };
    const postAuthLogout = async (): Promise<IPostLogoutByUser> => {
        const res = await fetches.postLogoutByUser();

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };

    return {
        withTOTP,
        postCheckAuth,
        postAuth,
        postAuthLogout,
    };
}

export function useAuthsBySuperAdmin() {
    const [auths, setAuths] = useState<IAUthSuperAdmin[] | null>(null);

    const getAuths = async (userId: number): Promise<IGetBySuperAdmin> => {
        const res = await fetches.getBySuperAdmin(userId);

        if (!res.auths || res.error) {
            console.log(res.error);

            return res;
        }

        setAuths(res.auths);

        return res;
    };

    return {
        auths,
        getAuths,
    };
}
