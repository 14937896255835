import {
    IFieldType,
} from '../parseField/types';
import {
    IArrayDescription,
    IArrayResult,
} from './types';

export default function getDefaultArray<IType extends IFieldType>(description: IArrayDescription<IType>): IArrayResult<IType>['value'] {
    return description.default;
}
