import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';

const ArrowImage: FC<IProps> = (props) => {
    return (
        <svg
            className={props.className}
            xmlns={'http://www.w3.org/2000/svg'}
            width={'9'}
            height={'16'}
            viewBox={'0 0 9 16'}
            fill={'none'}
            onClick={props.onClick}
        >
            <path
                fillRule={'evenodd'}
                clipRule={'evenodd'}
                d={'M0.594434 2.40864C0.203548 2.01775 0.203548 1.384 0.594434 0.993115C0.98532 0.60223 1.61907 0.60223 2.00996 0.993115L8.30969 7.29284C8.70021 7.68337 8.70021 8.31653 8.30969 8.70706L2.00996 15.0068C1.61907 15.3977 0.98532 15.3977 0.594434 15.0068C0.203548 14.6159 0.203548 13.9822 0.594434 13.5913L6.18575 7.99995L0.594434 2.40864Z'}
                fill={'#30313D'}
            />
        </svg>

    );
};

export default ArrowImage;
