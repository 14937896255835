import {
    IPaymentMethodUser,
} from './types';
import {
    IPatchByUserBody,
    IPostByUserBody,
} from './types/requests';
import {
    IGetByUser,
    IGetOneByUser,
    IPatchByUser,
    IPostByUser,
} from './types/responses';

import {
    useState,
} from 'react';
import {
    atom,
    useRecoilState,
} from 'recoil';

import * as fetches from './fetches';

const paymentMethodsAtom = atom<IPaymentMethodUser[] | null>({
    key: 'paymentMethodsAtom',
    default: null,
});

export function usePaymentMethodsByUser() {
    const [paymentMethods, setPaymentMethods] = useRecoilState(paymentMethodsAtom);

    const getPaymentMethods = async (): Promise<IGetByUser> => {
        const res = await fetches.getByUser();

        if (!res.paymentMethods || res.error) {
            console.log(res.error);

            return res;
        }

        setPaymentMethods(res.paymentMethods);

        return res;
    };

    return {
        paymentMethods,
        getPaymentMethods,
    };
}

export function usePaymentMethodByUser() {
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethodUser | null>(null);

    const getPaymentMethod = async (id: string): Promise<IGetOneByUser> => {
        const res = await fetches.getOneByUser(id);

        if (!res.paymentMethod || res.error) {
            console.log(res.error);

            return res;
        }

        setPaymentMethod(res.paymentMethod);

        return res;
    };
    const postPaymentMethod = async (body: IPostByUserBody): Promise<IPostByUser> => {
        const res = await fetches.postByUser(body);

        if (res.duplicatedIdError) {
            return res;
        }
        if (res.duplicatedNameError) {
            return res;
        }
        if (!res.paymentMethod || res.error) {
            console.log(res.error);

            return res;
        }

        setPaymentMethod(res.paymentMethod);

        return res;
    };
    const patchPaymentMethod = async (body: IPatchByUserBody): Promise<IPatchByUser> => {
        const res = await fetches.patchByUser(body);

        if (res.duplicatedNameError) {
            return res;
        }
        if (!res.paymentMethod || res.error) {
            console.log(res.error);

            return res;
        }

        setPaymentMethod(res.paymentMethod);

        return res;
    };

    return {
        paymentMethod,
        getPaymentMethod,
        postPaymentMethod,
        patchPaymentMethod,
    };
}
