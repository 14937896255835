import {
    ICurrencyUser,
} from './types';
import {
    IGetByUser,
} from './types/responses';

import {
    atom,
    useRecoilState,
} from 'recoil';

import * as fetches from './fetches';

const currenciesAtom = atom<ICurrencyUser[] | null>({
    key: 'currenciesAtom',
    default: null,
});

export function useCurrenciesByUser() {
    const [currencies, setCurrencies] = useRecoilState(currenciesAtom);

    const getCurrencies = async (): Promise<IGetByUser> => {
        const res = await fetches.getByUser();

        if (!res.currencies || res.error) {
            console.log(res.error);

            return res;
        }

        setCurrencies(res.currencies);

        return res;
    };

    return {
        currencies,
        getCurrencies,
    };
}
