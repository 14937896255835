import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useRef,
    useState,
} from 'react';
import {
    useNavigate,
} from 'react-router-dom';

import {
    useClickOutside,
} from 'tools/hooks';

import {
    useAuthByUser,
} from 'entities/auth/hooks';
import {
    useUserByMe,
} from 'entities/users/hooks';

import {
    PATH,
} from 'pages/constants';

import arrowImage from './media/arrow.svg';
import personImage from './media/person.svg';

import styles from './AuthInfo.module.scss';

const AuthInfo: FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const {authUser} = useUserByMe();
    const {postAuthLogout} = useAuthByUser();

    const navigate = useNavigate();

    const containerRef = useRef<HTMLDivElement>(null);

    const onLogout = async () => {
        await postAuthLogout();
        window.location.replace(PATH.SIGN_IN);
    };

    useClickOutside(containerRef, () => {
        setIsOpen(false);
    });

    if (!authUser) {
        return null;
    }

    return (
        <div
            ref={containerRef}
            className={
                classNames(styles.authInfo, props.className, {
                    [styles.isOpened]: isOpen,
                })
            }
            onClick={() => setIsOpen(!isOpen)}
        >
            <img
                className={styles.personImage}
                src={personImage}
                alt={''}
            />
            {authUser.username}
            <img
                className={styles.arrowImage}
                src={arrowImage}
                alt={''}
            />
            {
                isOpen &&
                <div className={styles.options}>
                    <div
                        className={styles.option}
                        onClick={() => navigate(PATH.SETTINGS)}
                    >
                        Settings
                    </div>
                    <div className={styles.line}/>
                    <div
                        className={styles.option}
                        onClick={onLogout}
                    >
                        Log out
                    </div>
                </div>
            }
        </div>
    );
};

export default AuthInfo;
