import {
    IGetBySuperAdminQuery,
    IGetByUserQuery,
    IPatchBySuperAdminBody,
    IPostBySuperAdminBody,
    IPostResetPasswordByUserBody,
} from './types/requests';
import {
    IDeleteBySuperAdmin,
    IGetBySuperAdmin,
    IGetByUser,
    IGetOneByMe,
    IGetOneBySuperAdmin,
    IPatchBySuperAdmin,
    IPostBySuperAdmin,
    IPostRecoverBySuperAdmin,
    IPostResetPasswordByUser,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    onUnauthorized,
} from 'entities/auth/functions';

import {
    INSTANCE_PATH,
    RESPONSE_ERROR,
} from './constants';

export async function getByUser(query: IGetByUserQuery): Promise<IGetByUser> {
    return new Fetch({
        url: `${config.URL}${INSTANCE_PATH}/user`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getBySuperAdmin(query: IGetBySuperAdminQuery): Promise<IGetBySuperAdmin> {
    return new Fetch<IGetBySuperAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/super_admin`,
        method: 'GET',
        query,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getOneByMe(): Promise<IGetOneByMe> {
    return new Fetch<IGetOneByMe>({
        url: `${config.URL}${INSTANCE_PATH}/me`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([400, 401, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getOneBySuperAdmin(id: number): Promise<IGetOneBySuperAdmin> {
    return new Fetch<IGetOneBySuperAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/super_admin/${id}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postBySuperAdmin(body: IPostBySuperAdminBody): Promise<IPostBySuperAdmin> {
    return new Fetch<IPostBySuperAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/super_admin`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.DUPLICATED_USERNAME: {
                    return {
                        duplicatedUsernameError: body.error,
                    };
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}

export async function postResetPasswordByUser(body: IPostResetPasswordByUserBody): Promise<IPostResetPasswordByUser> {
    return new Fetch<IPostResetPasswordByUser>({
        url: `${config.URL}${INSTANCE_PATH}/reset_password`,
        method: 'POST',
        body,
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 500], (body) => {
            return body;
        })
        .exec();
}

export async function patchBySuperAdmin(body: IPatchBySuperAdminBody): Promise<IPatchBySuperAdmin> {
    return new Fetch<IPatchBySuperAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/super_admin`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postRecoverBySuperAdmin(id: number): Promise<IPostRecoverBySuperAdmin> {
    return new Fetch<IPostRecoverBySuperAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/super_admin/recover/${id}`,
        method: 'POST',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function deleteSuperAdmin(id: number): Promise<IDeleteBySuperAdmin> {
    return new Fetch<IPostRecoverBySuperAdmin>({
        url: `${config.URL}${INSTANCE_PATH}/super_admin/${id}`,
        method: 'DELETE',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.DUPLICATED_USERNAME: {
                    return {
                        duplicatedUsernameError: body.error,
                    };
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}
