import {
    IDescriptionNotRequiredNotNullable,
    IDescriptionNotRequiredNullable,
    IDescriptionRequiredNotNullable,
    IDescriptionRequiredNullable,
    INumberDescription,
    INumberResult,
    IResultNotRequiredNotNullable,
    IResultNotRequiredNullable,
    IResultRequiredNotNullable,
    IResultRequiredNullable,
} from './types';

import {
    NULLABLE_VALUE,
    UNDEFINED_VALUE,
} from '../../constants';

function parseNumber(value: string, description: IDescriptionRequiredNullable): IResultRequiredNullable;
function parseNumber(value: string, description: IDescriptionRequiredNotNullable): IResultRequiredNotNullable;
function parseNumber(value: string, description: IDescriptionNotRequiredNullable): IResultNotRequiredNullable;
function parseNumber(value: string, description: IDescriptionNotRequiredNotNullable): IResultNotRequiredNotNullable;
function parseNumber(value: string, description: INumberDescription): INumberResult {
    if (value === UNDEFINED_VALUE) {
        if (description.isRequired) {
            return {
                value: description.default,
            };
        } else {
            return {
                value: undefined,
            };
        }
    }
    if (value === NULLABLE_VALUE) {
        if (description.isNullable) {
            return {
                value: null,
            };
        } else {
            return {
                value: description.default,
            };
        }
    }

    const parsedValue = description.isFloat ? parseFloat(value) : parseInt(value);

    if (isNaN(parsedValue)) {
        return {
            value: description.default,
        };
    }
    if (description.enum) {
        if (description.enum.includes(parsedValue)) {
            return {
                value: description.default,
            };
        }

        return {
            value: parsedValue,
        };
    }

    return {
        value: parsedValue,
    };
}

export default parseNumber;
