import {
    IFieldDescription,
    IFieldResultValue,
    IFieldType,
} from './types';

import {
    getDefaultBoolean,
} from './parseBoolean';
import {
    getDefaultDate,
} from './parseDate';
import {
    getDefaultNumber,
} from './parseNumber';
import {
    getDefaultString,
} from './parseString';

export default function getDefaultValue<IType extends IFieldType>(description: IFieldDescription<IType>): IFieldResultValue<IFieldType> {
    switch (description.type) {
        case 'boolean': {
            // @ts-ignore
            return getDefaultBoolean(description);
        }
        case 'date': {
            // @ts-ignore
            return getDefaultDate(description);
        }
        case 'number': {
            // @ts-ignore
            return getDefaultNumber(description);
        }
        case 'string': {
            // @ts-ignore
            return getDefaultString(description);
        }
    }
}
