import {
    useEffect,
} from 'react';

type ICallBack = () => void | Promise<void>;

export default function(cb: ICallBack, delay: number, dependencies: any[]) {
    useEffect(() => {
        const id = setTimeout(async () => {
            await cb();
        }, delay);

        return () => {
            clearTimeout(id);
        };
    }, dependencies);
}
