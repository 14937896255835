export enum PATH {
    SIGN_IN = '/',

    PAYMENTS = '/payments',
    ACCOUNTS = '/accounts',
    AGENTS = '/agents',
    PAYMENT_METHODS = '/payment_methods',
    MERCHANTS = '/merchants',
    USERS = '/users',
    ADMIN_PANEL = '/admin',

    SETTINGS = '/settings',
}
