import {
    IUserMe,
} from './types';
import {
    IGetBySuperAdminQuery,
    IGetByUserQuery,
} from './types/requests';

import {
    PERMISSION,
    ROLE,
} from './constants';

export function getDefaultQueryUser(take: number): IGetByUserQuery {
    return {
        skip: 0,
        take,

        username: '',
    };
}

export function getDefaultQuerySuperAdmin(): IGetBySuperAdminQuery {
    return {
        skip: 0,
        take: 20,

        username: '',
    };
}

export function hasPermission(user: IUserMe | null, permission: PERMISSION): boolean {
    if (!user) {
        return false;
    }

    return (
        user.role === ROLE.SUPER_ADMIN ||
        user.permissions.includes(permission)
    );
}

export function hasPermissions(user: IUserMe | null, permissions: PERMISSION[]): boolean {
    if (!user) {
        return false;
    }

    return permissions.some((permission) => hasPermission(user, permission));
}
