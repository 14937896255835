import React, {
    FC,
    lazy,
    Suspense,
} from 'react';
import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';
import {
    RecoilRoot,
} from 'recoil';

import {
    Container,
    Loading,
} from './components/other';
import {
    PATH,
} from './pages/constants';
import {
    useRedirectProtocol,
} from './tools/hooks';

const SignIn = lazy(() => import('./pages/SignIn'));

const Payments = lazy(() => import('./pages/Payments'));
const Payment = lazy(() => import('./pages/Payment'));
const Accounts = lazy(() => import('./pages/Accounts'));
const Account = lazy(() => import('./pages/Account'));
const Agents = lazy(() => import('./pages/Agents'));
const Agent = lazy(() => import('./pages/Agent'));
const PaymentMethods = lazy(() => import('./pages/PaymentMethods'));
const PaymentMethod = lazy(() => import('./pages/PaymentMethod'));
const Merchant = lazy(() => import('./pages/Merchant'));
const Merchants = lazy(() => import('./pages/Merchants'));
const Users = lazy(() => import('./pages/Users'));
const User = lazy(() => import('./pages/User'));
const AdminPanel = lazy(() => import('./pages/AdminPanel'));

const Settings = lazy(() => import('./pages/Settings'));
const NotFound = lazy(() => import('./pages/NotFound'));

const App: FC = () => {
    useRedirectProtocol();

    return (
        <RecoilRoot>
            <BrowserRouter>
                <Container>
                    <Suspense fallback={<Loading isPage={true}/>}>
                        <Routes>
                            <Route
                                path={PATH.SIGN_IN}
                                element={<SignIn/>}
                            />
                            <Route
                                path={PATH.PAYMENTS}
                                element={<Payments/>}
                            />
                            <Route
                                path={`${PATH.PAYMENTS}/:actionPath/:id?`}
                                element={<Payment/>}
                            />
                            <Route
                                path={PATH.ACCOUNTS}
                                element={<Accounts/>}
                            />
                            <Route
                                path={`${PATH.ACCOUNTS}/:actionPath/:id?`}
                                element={<Account/>}
                            />
                            <Route
                                path={PATH.AGENTS}
                                element={<Agents/>}
                            />
                            <Route
                                path={`${PATH.AGENTS}/:actionPath/:id?`}
                                element={<Agent/>}
                            />
                            <Route
                                path={PATH.PAYMENT_METHODS}
                                element={<PaymentMethods/>}
                            />
                            <Route
                                path={`${PATH.PAYMENT_METHODS}/:actionPath/:id?`}
                                element={<PaymentMethod/>}
                            />
                            <Route
                                path={PATH.MERCHANTS}
                                element={<Merchants/>}
                            />
                            <Route
                                path={`${PATH.MERCHANTS}/:actionPath/:id?`}
                                element={<Merchant/>}
                            />
                            <Route
                                path={PATH.USERS}
                                element={<Users/>}
                            />
                            <Route
                                path={`${PATH.USERS}/:actionPath/:id?`}
                                element={<User/>}
                            />
                            <Route
                                path={PATH.ADMIN_PANEL}
                                element={<AdminPanel/>}
                            />
                            <Route
                                path={PATH.SETTINGS}
                                element={<Settings/>}
                            />
                            <Route
                                path={'*'}
                                element={<NotFound/>}
                            />
                        </Routes>
                    </Suspense>
                </Container>
            </BrowserRouter>
        </RecoilRoot>
    );
};

export default App;
