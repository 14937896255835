import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import Tab from './Tab';

import styles from './Tabs.module.scss';

const Tabs: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.tabs, props.className)}>
            {
                props.tabs.map(
                    (tab) =>
                        <Tab
                            key={tab.name}
                            title={props.formatTabTitle(tab.name)}
                            isActive={tab.isActive}
                            onClick={() => props.onChange(tab.name)}
                        />
                )
            }
        </div>
    );
};

export default Tabs;
