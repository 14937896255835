import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import BlockTitle from './BlockTitle';

import styles from './FormBlock.module.scss';

const FormBlock: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.formBlock, props.className)}>
            <BlockTitle
                className={styles.title}
                text={props.title}
            />
            <div className={classNames(styles.content, props.contentClassName)}>
                {props.children}
            </div>
        </div>
    );
};

export default FormBlock;
