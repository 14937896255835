import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';
import ReactCalendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';

const Calendar: FC<IProps> = (props) => {
    return (
        <ReactCalendar
            className={props.className}
            value={props.value}
            onChange={(value) => props.onChangeValue(value as Date | null)}
        />
    );
};

export default Calendar;
