import {
    ILanguageUser,
} from './types';
import {
    IGetByUser,
} from './types/responses';

import {
    atom,
    useRecoilState,
} from 'recoil';

import * as fetches from './fetches';

const languagesAtom = atom<ILanguageUser[] | null>({
    key: 'languagesAtom',
    default: null,
});

export function useLanguagesByUser() {
    const [languages, setLanguages] = useRecoilState(languagesAtom);

    const getLanguages = async (): Promise<IGetByUser> => {
        const res = await fetches.getByUser();

        if (!res.languages || res.error) {
            console.log(res.error);

            return res;
        }

        setLanguages(res.languages);

        return res;
    };

    return {
        languages,
        getLanguages,
    };
}
