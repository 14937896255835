import {
    IUserMe,
} from 'entities/users/types';

import {
    PERMISSION,
    ROLE,
} from 'entities/users/constants';

import {
    PATH,
} from 'pages/constants';

export function onUnauthorized() {
    window.location.replace('/');
}

export function getStartPage(authUser: IUserMe, currentPath: string): string {
    if (currentPath !== PATH.SIGN_IN) {
        return currentPath;
    }
    if (authUser.role === ROLE.SUPER_ADMIN) {
        return PATH.USERS;
    }
    if (authUser.permissions.includes(PERMISSION.PAYMENTS_VIEW)) {
        return PATH.PAYMENTS;
    }
    if (authUser.permissions.includes(PERMISSION.ACCOUNTS_VIEW)) {
        return PATH.ACCOUNTS;
    }
    if (authUser.permissions.includes(PERMISSION.PAYMENT_METHODS_VIEW)) {
        return PATH.PAYMENT_METHODS;
    }
    if (authUser.permissions.includes(PERMISSION.MERCHANTS_VIEW)) {
        return PATH.MERCHANTS;
    }

    return PATH.SETTINGS;
}
