import {
    IBooleanDescription,
    IBooleanResult,
    IDescriptionNotRequiredNotNullable,
    IDescriptionNotRequiredNullable,
    IDescriptionRequiredNotNullable,
    IDescriptionRequiredNullable,
    IResultNotRequiredNotNullable,
    IResultNotRequiredNullable,
    IResultRequiredNotNullable,
    IResultRequiredNullable,
} from './types';

import {
    NULLABLE_VALUE,
    UNDEFINED_VALUE,
} from '../../constants';

function parseBoolean(value: string, description: IDescriptionRequiredNullable): IResultRequiredNullable;
function parseBoolean(value: string, description: IDescriptionRequiredNotNullable): IResultRequiredNotNullable;
function parseBoolean(value: string, description: IDescriptionNotRequiredNullable): IResultNotRequiredNullable;
function parseBoolean(value: string, description: IDescriptionNotRequiredNotNullable): IResultNotRequiredNotNullable;
function parseBoolean(value: string, description: IDescriptionNotRequiredNotNullable): IResultNotRequiredNotNullable;
function parseBoolean(value: string, description: IBooleanDescription): IBooleanResult {
    if (value === UNDEFINED_VALUE) {
        if (description.isRequired) {
            return {
                value: description.default,
            };
        } else {
            return {
                value: undefined,
            };
        }
    }
    if (value === NULLABLE_VALUE) {
        if (description.isNullable) {
            return {
                value: null,
            };
        } else {
            return {
                value: description.default,
            };
        }
    }
    if (!['true', 'false'].includes(value)) {
        return {
            value: description.default,
        };
    }

    const parsedValue = value === 'true';

    return {
        value: parsedValue,
    };
}

export default parseBoolean;
