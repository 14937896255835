import {
    IPatchByUserBody,
    IPostByUserBody,
} from './types/requests';
import {
    IGetByUser,
    IGetOneByUser,
    IPatchByUser,
    IPostByUser,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    onUnauthorized,
} from 'entities/auth/functions';

import {
    INSTANCE_PATH,
    RESPONSE_ERROR,
} from './constants';

export async function getByUser(): Promise<IGetByUser> {
    return new Fetch<IGetByUser>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getOneByUser(id: string): Promise<IGetOneByUser> {
    return new Fetch<IGetOneByUser>({
        url: `${config.URL}${INSTANCE_PATH}/${id}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postByUser(body: IPostByUserBody): Promise<IPostByUser> {
    return new Fetch<IPostByUser>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.DUPLICATED_ID: {
                    return {
                        duplicatedIdError: body.error,
                    };
                }
                case RESPONSE_ERROR.DUPLICATED_NAME: {
                    return {
                        duplicatedNameError: body.error,
                    };
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}

export async function patchByUser(body: IPatchByUserBody): Promise<IPatchByUser> {
    return new Fetch<IPatchByUser>({
        url: `${config.URL}${INSTANCE_PATH}`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.DUPLICATED_NAME: {
                    return {
                        duplicatedNameError: body.error,
                    };
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}
