interface IConfig {
    MODE: 'DEV' | 'TEST' | 'PROD';
    URL: string;
}

const config: IConfig = {
    MODE: String(process.env.REACT_APP_MODE) as 'DEV' | 'TEST' | 'PROD',
    URL: eval(process.env.REACT_APP_URL ?? ''),
};

export default config;
