import {
    createRef,
    useEffect,
    useState,
} from 'react';

export interface IArrayItemRef<IValue> {
    getValue(): IValue;
    setValue(value: IValue | null): void;
    validate(): boolean;
}

export default function useRefsArray<IValue, IRef extends IArrayItemRef<IValue>>(values: IValue[]) {
    const [refs, setRefs] = useState(values.map(() => createRef<IRef>()));
    const [items, setItems] = useState<(IValue | null)[]>([...values]);

    const addRef = () => {
        const newRefs = Array(refs.length + 1)
            .fill(null)
            .map(() => createRef<IRef>());

        setRefs(newRefs);
        setItems([null, ...refs.map((ref) => (ref.current?.getValue()) || null)]);
    };
    const pushRef = () => {
        const newRefs = Array(refs.length + 1)
            .fill(null)
            .map(() => createRef<IRef>());

        setRefs(newRefs);
        setItems([...refs.map((ref) => (ref.current?.getValue()) || null), null]);
    };
    const removeRef = (index: number) => {
        const newRefs = Array(refs.length - 1)
            .fill(null)
            .map(() => createRef<IRef>());

        setRefs(newRefs);
        setItems(
            refs
                .filter((_, i) => i !== index)
                .map((ref) => ref.current?.getValue() || null)
        );
    };
    const validateRefs = (): boolean => {
        return !refs
            .map((ref) => ref.current?.validate())
            .includes(false);
    };
    const extractRefs = (): IValue[] => {
        return refs
            .map((ref) => ref.current?.getValue() || null)
            .filter((value) => !!value) as IValue[];
    };
    const resetRefs = (): void => {
        setRefs(values.map(() => createRef<IRef>()));
        setItems([...values]);
    };

    useEffect(() => {
        items.forEach((item, i) => {
            refs?.[i]?.current?.setValue(item);
        });
    }, [items]);

    return {
        refs,
        addRef,
        pushRef,
        removeRef,
        validateRefs,
        extractRefs,
        resetRefs,
    };
}
