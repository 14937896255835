import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './Tab.module.scss';

const Tab: FC<IProps> = (props) => {
    return (
        <div
            className={
                classNames(styles.tab, props.className, {
                    [styles.isActive]: props.isActive,
                })
            }
            onClick={props.onClick}
        >
            {props.title}
        </div>
    );
};

export default Tab;
