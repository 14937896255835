class DateTime {
    private static addZero(value: number): string {
        return `${value < 10 ? 0 : ''}${value}`;
    }

    public static formatDate(value: any): string {
        value = new Date(value);

        if (isNaN(value.valueOf())) {
            return '';
        }

        const day = DateTime.addZero(value.getDate());
        const month = DateTime.addZero(value.getMonth() + 1);
        const year = value.getFullYear();

        return `${day}.${month}.${year}`;
    }

    public static formatTime(value: any): string {
        value = new Date(value);

        if (isNaN(value.valueOf())) {
            return '';
        }

        const hours = DateTime.addZero(value.getHours());
        const minutes = DateTime.addZero(value.getMinutes());
        const seconds = DateTime.addZero(value.getSeconds());

        return `${hours}:${minutes}:${seconds}`;
    }

    public static formatDateTime(value: any): string {
        if (value === null) {
            return '-';
        }

        const date = DateTime.formatDate(value);
        const time = DateTime.formatTime(value);

        return `${date} ${time}`;
    }

    public static convertNumberToDate(value: number | null): string | null {
        if (value === null) {
            return null;
        }

        const date = new Date(value);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return `${DateTime.addZero(year)}-${DateTime.addZero(month)}-${DateTime.addZero(day)}`;
    }

    public static convertNumberToTime(value: number | null): string | null {
        if (value === null) {
            return null;
        }

        const date = new Date(value);
        const hours = date.getHours();
        const minutes = date.getMinutes();

        return `${DateTime.addZero(hours)}:${DateTime.addZero(minutes)}`;
    }

    public static convertUTCToDateAndTime(value: string): [date: string | null, time: string | null] {
        const date = new Date(value);

        return [DateTime.convertNumberToDate(date.getTime()), DateTime.convertNumberToTime(date.getTime())];
    }
}

export default DateTime;
