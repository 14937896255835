import {
    IFieldDescription,
    IFieldResult,
    IFieldType,
} from './types';

import {
    parseBoolean,
} from './parseBoolean';
import {
    parseDate,
} from './parseDate';
import {
    parseNumber,
} from './parseNumber';
import {
    parseString,
} from './parseString';

export default function parseField<IType extends IFieldType>(value: string, description: IFieldDescription<IType>): IFieldResult<IFieldType> {
    switch (description.type) {
        case 'boolean': {
            // @ts-ignore
            return parseBoolean(value, description);
        }
        case 'date': {
            // @ts-ignore
            return parseDate(value, description);
        }
        case 'number': {
            // @ts-ignore
            return parseNumber(value, description);
        }
        case 'string': {
            // @ts-ignore
            return parseString(value, description);
        }
    }
}
