import {
    IAccountUser,
} from './types';
import {
    IPatchByUserBody,
    IPostByUserBody,
    IPostStatusByUserBody,
} from './types/requests';
import {
    IGetByUser,
    IGetOneByUser,
    IPatchByUser,
    IPostByUser,
    IPostStatusByUser,
} from './types/responses';

import {
    useState,
} from 'react';
import {
    atom,
    useRecoilState,
} from 'recoil';

import Entity from 'helpers/Entity';

import * as fetches from './fetches';

const accountsAtom = atom<IAccountUser[] | null>({
    key: 'accountsAtom',
    default: null,
});

export function useAccountsByUser() {
    const [accounts, setAccounts] = useRecoilState(accountsAtom);

    const getAccounts = async (): Promise<IGetByUser> => {
        const res = await fetches.getByUser();

        if (!res.accounts || res.error) {
            console.log(res.error);

            return res;
        }

        setAccounts(res.accounts);

        return res;
    };
    const postAccountStatus = async (body: IPostStatusByUserBody): Promise<IPostStatusByUser> => {
        const res = await fetches.postStatusByUser(body);

        if (!res.account || res.error) {
            console.log(res.error);

            return res;
        }

        setAccounts((accounts) => {
            return Entity.replace(res.account, accounts);
        });

        return res;
    };

    return {
        accounts,
        getAccounts,
        postAccountStatus,
    };
}

export function useAccountByUser() {
    const [account, setAccount] = useState<IAccountUser | null>(null);

    const getAccount = async (id: number): Promise<IGetOneByUser> => {
        const res = await fetches.getOneByUser(id);

        if (!res.account || res.error) {
            console.log(res.error);

            return res;
        }

        setAccount(res.account);

        return res;
    };
    const postAccount = async (body: IPostByUserBody): Promise<IPostByUser> => {
        const res = await fetches.postByUser(body);

        if (!res.account || res.error) {
            console.log(res.error);

            return res;
        }

        setAccount(res.account);

        return res;
    };
    const patchAccount = async (body: IPatchByUserBody): Promise<IPatchByUser> => {
        const res = await fetches.patchByUser(body);

        if (!res.account || res.error) {
            console.log(res.error);

            return res;
        }

        setAccount(res.account);

        return res;
    };

    return {
        account,
        getAccount,
        postAccount,
        patchAccount,
    };
}
